import React, { useEffect } from "react";

const GoogleAds = ({ dataAdSlot, customClass = "" }) => {
  useEffect(() => {
    try {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <>
      <ins
        className={`adsbygoogle ${customClass}`}
        style={{ display: "block" }}
        data-ad-client="ca-pub-3301512886719872"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};

export default GoogleAds;
