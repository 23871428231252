import moment from "moment";
import "./ListCard.scss";
import { useNavigate } from "react-router-dom";

export const ListCard = ({ article }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/content/${article._id}/${article.slug}`, { replace: true });
  };

  return (
    <div className="article-list-card" onClick={handleClick}>
      <img className="article-list-card-img" src={article?.featuredImage} />
      <div className="article-list-card-article-meta">
        <div className="article-list-card-title">{article?.title}</div>
        <div className="article-list-card-read-time">
          {moment(article?.createdAt).fromNow()}
        </div>
      </div>
    </div>
  );
};
