import "./CommentList.scss";
import React from "react";
import { SingleComment } from "./SingleComment";

export const CommentsList = ({ commentsData }) => {
  return (
    <div>
      {(commentsData?.items || []).map((comment) => (
        <SingleComment key={comment._id} comment={comment} level={0} />
      ))}
    </div>
  );
};
