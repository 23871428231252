import { Button } from "antd";
import { EditorMetadata } from "../Components/ArticleEditor/EditorMetadata";
import {
  createArticle,
  editArticle,
  getSingleArticle,
  getSubTopics,
  getTopics,
  uploadAssetToS3,
} from "../commons/articleApis";
import { Input, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import Popup from "../Components/Shared/Popup";
import CustomBtn from "../Components/Shared/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../redux/slice/userSlice";
import { EditorNew } from "../Components/ArticleEditor/EditorNew";
import { getAllTopics } from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./WriteArticle.scss";
import { FileUploader } from "../Components/FileUploader/FileUploader";
import Progress from "../Components/Shared/Progress/Progress";
import { setTopics } from "../redux/slice/topicSlice";

const articleFormSchema = yup.object().shape({
  title: yup
    .string()
    .min(10)
    .max(120)
    .typeError("Title is required")
    .required(),
  subtitle: yup.string().max(30),
  richContent: yup.object(),
  featuredImage: yup
    .string()
    .required()
    .typeError("Featured image is required"),
  topic: yup.object().default(null).required().typeError("Topic is required"),
  subtopic: yup
    .array()
    .min(1)
    .max(5)
    .required()
    .typeError("Subtopic is required"),
});

function WriteArticle() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      title: "",
      subtitle: "",
      richContent: null,
      topic: null,
    },
    reValidateMode: "onChange",
    mode: "all",
    resolver: yupResolver(articleFormSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  // const { editArticle } = useParams();
  const dispatch = useDispatch();
  const { articleId } = useParams();
  const { topics } = useSelector((state) => state.topic);

  const [subtopics, setSubtopics] = useState([]);

  const editorCore = useRef(null);
  const [publishModal, setPublishModal] = useState(false);

  const [selectedImg, setSelectedimg] = useState(null);
  const [noImgSelected, setNoImgSelected] = useState("");

  const featuredImage = watch("featuredImage");
  const topic = watch("topic");
  const subtopic = watch("subtopic");
  const richContent = watch("richContent");
  const title = watch("title");
  const subtitle = watch("subtitle");

  useEffect(() => {
    if (topics.length === 0) getAllTopics(dispatch);

    if (articleId) {
      getArticle();
    } else {
      const defaultVal = {
        time: 1689185386905,
        blocks: [],
        version: "2.27.2",
      };
      setValue("richContent", defaultVal);
    }
  }, []);

  const getArticle = async () => {
    try {
      setIsLoading(true);
      const { data } = await getSingleArticle(articleId);

      if (data.topicData) getAllSubTopics(data.topicData._id);

      const articleContent = JSON.parse(data.richContent);

      setValue("title", data.title);
      setValue("subtitle", data.subtitle);
      setValue("topic", data.topicData);
      setValue("subtopic", data.subtopicsData);
      setValue("richContent", articleContent);

      setSelectedimg(data.featuredImage);
      setValue("featuredImage", data.featuredImage);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const uploadAsset = async () => {
    try {
      const formData = new FormData();
      formData.append("file", featuredImage);
      formData.append("assetType", "article_featured_image");

      const response = await uploadAssetToS3(formData);

      if (response.data.url) {
        return {
          success: 1,
          file: response.data,
        };
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Function to remove HTML tags and links
  const removeHtmlAndLinks = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const plainText = doc.body.textContent || "";
    return plainText;
  };

  // check if a URL contains a specific domain anywhere in the URL
  const containsDomain = (url, domain) => {
    const regex = new RegExp(domain.replace(".", "\\."), "i");
    return regex.test(url);
  };

  const handlePublish = async () => {
    try {
      setIsLoading(true);

      let savedData;

      if (richContent?._editorJS) {
        savedData = await richContent?._editorJS?.save();
      } else {
        savedData = await richContent?.saver?.save();
      }

      const plainContent = savedData.blocks
        .map((block) => block.data.text)
        .join(" ");

      const sanitizedPlainContent = removeHtmlAndLinks(plainContent);

      if (!savedData) {
        throw {
          response: { data: { message: "Article content is required" } },
        };
      }

      const payload = {
        title: title.trim(),
        subtitle: subtitle.trim(),
        topicId: topic._id,
        subtopics: subtopic.map((item) => item._id),
        plainContent: sanitizedPlainContent,
        richContent: JSON.stringify(savedData),
        featuredImage: selectedImg,
      };

      const domain = "amazonaws.com";
      const isAwsURL = containsDomain(featuredImage, domain);

      if (!selectedImg) {
        setNoImgSelected("Featured img is required");
      }

      if (!isAwsURL && featuredImage !== null) {
        const data = await uploadAsset();

        payload["featuredImage"] = data.file?.url;
      }

      let response;
      if (articleId) {
        response = await editArticle(articleId, payload);
      } else {
        response = await createArticle(payload);
      }

      if ([200, 201].includes(response.status)) {
        const { message, articleId, slug } = response?.data;
        reset();

        dispatch(
          setAlert({
            severity: "success",
            open: true,
            message: message,
          })
        );

        navigate(`/content/${articleId}/${slug}`);
      }

      setPublishModal(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e?.response?.data
            ? e.response.data.message
            : "Something went wrong",
        })
      );
    } finally {
      setPublishModal(false);
      setIsLoading(false);
    }
  };

  const getAllSubTopics = async (topicId) => {
    try {
      const subtopics = await getSubTopics(topicId);
      setSubtopics(subtopics.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePublishModalOpen = () => {
    setPublishModal(true);
  };

  const handlePublishModalClose = () => {
    setPublishModal(false);
  };

  const onSubmitError = () => {
    setPublishModal(false);
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? (
        <Progress />
      ) : (
        <>
          <div className="write-article base-margin">
            <div className="publish-btn-container">
              <Button className="text-btn discard-btn" onClick={handleDiscard}>
                Discard
              </Button>
              <Button
                className="default-action-btn std-shape-round gradient-bg-two"
                type="primary"
                onClick={handlePublishModalOpen}
              >
                {articleId ? "Apply Changes" : "Publish"}
              </Button>
            </div>
            <div className="article-pg-heading-txt">
              Unleash your{" "}
              <span className="article-pg-heading-special-underline">
                creativity
              </span>{" "}
              and compose a{" "}
              <span className="article-pg-heading-special-underline">
                magnificent
              </span>{" "}
              masterpiece of an article.
            </div>

            <div className="article-container">
              <div className="d-flex flex-column">
                <div className="article-input-fields">
                  Title <span className="text-danger">*</span>
                </div>

                <input
                  {...register("title")}
                  className="article-title"
                  placeholder="New post title here..."
                  value={title}
                />
                <p className="text-danger">{errors.title?.message}</p>

                <div className="article-input-fields">
                  Subtitle <span>(optional)</span>
                </div>
                <input
                  {...register("subtitle")}
                  className="article-subtitle"
                  placeholder="subtitle goes here..."
                  value={subtitle}
                />
                <p className="text-danger">{errors.subtitle?.message}</p>
              </div>

              <div className="article-input-fields mb-3">
                Featured image <span className="text-danger">*</span>
              </div>

              <FileUploader
                setValue={setValue}
                imgVarName="featuredImage"
                acceptFileType="image/*"
                uploadtxt="Select Featured Img. Asset ratio (1400px * 900px)"
                isImg={true}
                selectedImg={selectedImg}
                setSelectedimg={setSelectedimg}
                register={register}
              />
              <p className="text-danger mt-2">
                {errors.featuredImage?.message}
              </p>

              <EditorMetadata
                topics={topics}
                subtopics={subtopics}
                selectedTopic={topic}
                selectedSubtopic={subtopic}
                setValue={setValue}
                getAllSubTopics={getAllSubTopics}
                errors={errors}
                register={register}
              />

              <div className="article-input-fields mt-4 mb-3">
                Writing Space <span className="text-danger">*</span>
              </div>
              <p className="text-danger">{errors.richContent?.message}</p>

              <EditorNew richContent={richContent} setValue={setValue} />
            </div>
          </div>

          {publishModal && (
            <Popup isOpen={publishModal} handleClose={handlePublishModalClose}>
              <div className="mb-4">
                {articleId ? (
                  "Do you want to proceed with the changes to the article?"
                ) : (
                  <>
                    <h2 className="mb-4 text-center">Time to go live!</h2>
                    <div>Confirm to release your article to eager readers.</div>
                  </>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <CustomBtn
                  customBtnClass="me-4"
                  onBtnClick={handleSubmit(handlePublish, onSubmitError)}
                  btnTxt="Go for it!"
                  type={"submit"}
                  disabled={isLoading}
                />
                <CustomBtn
                  onBtnClick={handlePublishModalClose}
                  btnTxt="Hold on, not ready!"
                  variant="text"
                />
              </div>
            </Popup>
          )}
        </>
      )}
    </>
  );
}

export default WriteArticle;
