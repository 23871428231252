import { logout } from "../commons/api";
import {
  bookmarkArticle,
  getTopics,
  likeArticle,
} from "../commons/articleApis";
import { setTopics } from "../redux/slice/topicSlice";
import { setAlert, setUserData } from "../redux/slice/userSlice";

// returns cookie value
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop().split(";").shift();
};

// logs out the user from the app
export const logoutUser = async (
  tokenName,
  removeCookie,
  dispatch,
  navigate
) => {
  try {
    // const response = await logout();
    removeCookie(tokenName, { path: "/", domain: ".liongueststudios.com" });
    dispatch(setUserData(null));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  } catch (e) {
    console.log(e);
  }
};

export const handleLikeBtnClick = async (articleId, dispatch) => {
  try {
    return await likeArticle(articleId);
  } catch (e) {
    console.log(e);
    dispatch(
      setAlert({
        severity: "error",
        open: true,
        message: e.response.data.message,
      })
    );
  }
};

export const handleBookmarkBtnClick = async (articleId, dispatch) => {
  try {
    return await bookmarkArticle(articleId);
  } catch (e) {
    console.log(e);
    dispatch(
      setAlert({
        severity: "error",
        open: true,
        message: e.response.data.message,
      })
    );
  }
};

// get topics data
export const getAllTopics = async (dispatch) => {
  try {
    const topics = await getTopics();
    dispatch(setTopics(topics.data));
  } catch (e) {
    console.log(e);
  }
};

export const copyTextToClipboard = (text, successMsg, dispatch) => {
  navigator.clipboard.writeText(text);
  dispatch(
    setAlert({
      severity: "success",
      open: true,
      message: successMsg,
    })
  );
};

export const removeHtmlAndLinks = (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  const plainText = doc.body.textContent || "";
  return plainText;
};
