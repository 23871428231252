import { useNavigate } from "react-router-dom";
import "./WriterCard.scss";

const WriterCard = ({ writer }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/profile/${writer.username}`);
  };
  return (
    <>
      <div className="writer-card std-shape-round" onClick={handleClick}>
        <img className="writer-img" src={writer.profile_pic} />
        <div className="writer-details">
          <div className="writer-full-name">{`${writer.first_name} ${writer.last_name}`}</div>
          <div className="writer-bio">{writer.bio}</div>
        </div>
      </div>
    </>
  );
};

export default WriterCard;
