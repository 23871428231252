import "./ArticleCard.scss";
import AuthorImg from "../../assets/images/sahil_bhosale.png";
import IosShareIcon from "@mui/icons-material/IosShare";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { likeArticle } from "../../commons/articleApis";
import { setAlert } from "../../redux/slice/userSlice";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { copyTextToClipboard } from "../../utils/utils";
import BookmarkIcon from "@mui/icons-material/Bookmark";

function ArticleCard({
  article,
  hideCardEngagements = false,
  hideBookmark = false,
  hideUserDetails = false,
  handleCardContentClick,
  likeBtnClick,
  bookmarkBtnClick,
}) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  return (
    <div
      className="article-card-container"
      onClick={() => handleCardContentClick(article)}
    >
      <div className="img-container">
        <img src={article?.featuredImage} className="card-featured-img" />
        {!hideBookmark && (
          <div className="card-bookmark">
            <button
              className="card-bookmark-btn"
              onClick={(e) => bookmarkBtnClick(e, article)}
            >
              {article?.bookmarksData?.length > 0 &&
              article?.bookmarksData[0]?.bookmarkedBy?.includes(
                userData?.userId
              ) ? (
                <BookmarkIcon
                  className="feed-card-bookmark-icon-select-color"
                  fontSize="large"
                />
              ) : (
                <BookmarkBorderIcon fontSize="large" />
              )}
            </button>
          </div>
        )}
      </div>
      <div className="card-contents">
        <div className="article-card-top-content">
          <div className="card-topic">{article?.topicData.topicName}</div>
          <h2 className="card-heading">{article?.title}</h2>
          <div className="card-description"></div>
        </div>

        <div className="article-card-bottom-content">
          {!hideUserDetails && (
            <div className="card-profile">
              <img
                className="author-img"
                src={article?.posterData?.profile_pic}
              />
              <div className="author-metadata">
                <div className="author-name">
                  {article?.posterData?.first_name}{" "}
                  {article?.posterData?.last_name}
                </div>
                {article?.posterData?.username && (
                  <span className="author-username">{`@${article?.posterData?.username}`}</span>
                )}
                <div className="article-date">
                  {moment(article?.createdAt).fromNow()}
                </div>
              </div>
            </div>
          )}
          {!hideCardEngagements && (
            <>
              <div className="divider-line"></div>
              <div className="card-engagements">
                <div
                  className="card-likes"
                  onClick={(e) => likeBtnClick(e, article)}
                >
                  {article?.likesData.length &&
                  article?.likesData[0]?.likedBy?.includes(userData?.userId) ? (
                    <FavoriteIcon
                      className="article-card-like-btn"
                      fontSize="large"
                    />
                  ) : (
                    <FavoriteBorderIcon
                      className="article-card-icon-unselect-color"
                      fontSize="large"
                    />
                  )}
                  <span className="ms-2">
                    {article?.likesData.length > 0
                      ? article?.likesData[0]?.likedBy?.length
                      : 0}{" "}
                    Likes
                  </span>
                </div>
                <div className="card-comments">
                  <ChatBubbleOutlineIcon fontSize="large" className="me-2" />
                  <span className="ms-2">
                    {article?.commentsData?.length} Comments
                  </span>
                </div>
                <button
                  className="card-share-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = `${window.location.href}content/${article?._id}/${article?.slug}`;
                    copyTextToClipboard(url, "Link copied!", dispatch);
                  }}
                >
                  <IosShareIcon className="share-icon" fontSize="large" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
