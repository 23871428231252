import { useState } from "react";
import CustomMenu from "../Shared/CustomMenu";
import "./SingleComment.scss";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplyIcon from "@mui/icons-material/Reply";
import CustomBtn from "../Shared/CustomBtn";
import DotsIcon from "../../assets/images/icons/dots.svg";

export const SingleComment = ({ comment, level }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const margin = level * 20;

  const handleReply = () => {
    // curr commnet id
  };

  const handleDeleteComment = () => {};

  const menuItems = [
    {
      id: 1,
      name: "Reply",
      icon: <ReplyIcon className="bookmark-icon" />,
      handleItemClick: () => handleReply(),
    },
    {
      id: 2,
      name: "Delete",
      icon: <DeleteOutlineIcon className="bookmark-icon" />,
      handleItemClick: () => handleDeleteComment(),
    },
  ];

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className="single-comment-container std-shape-round"
        style={{ marginLeft: margin }}
      >
        <div className="single-comment-user-and-menu">
          <div className="single-comment-users-details">
            <img
              className="single-comment-user-img"
              src={comment?.userData?.profile_pic}
            />
            <div className="single-comment-user-meta">
              <div className="single-comment-user-name">
                {comment?.userData?.first_name} {comment?.userData?.last_name}
              </div>
              <div className="single-comment-user-date">
                {moment(comment.createdAt).fromNow()}
              </div>
            </div>
          </div>
          <CustomBtn
            customBtnClass={"rounded"}
            variant="text"
            btnTxt={<img src={DotsIcon} onClick={handleDotsClick} />}
          />
        </div>

        <div>{comment.commentData}</div>
      </div>

      <div className="replies">
        {comment.repliesData.map((reply) => (
          <SingleComment key={reply._id} comment={reply} level={level + 1} />
        ))}
      </div>

      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleMenuClose}
        menuItems={menuItems}
      />
    </>
  );
};
