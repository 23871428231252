import { useEffect, useState } from "react";
import FeedCard from "../Components/FeedCard/FeedCard";
import "./UserFeed.scss";
import { getArticles } from "../commons/articleApis";
import { useDispatch } from "react-redux";
import { setAlert } from "../redux/slice/userSlice";
import CustomBtn from "../Components/Shared/CustomBtn";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { handleBookmarkBtnClick, handleLikeBtnClick } from "../utils/utils";
import GoogleAds from "../commons/component/GoogleAds";
import OgTags from "../utils/Ogtags";

const UserFeed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = async () => {
    try {
      const payload = {
        search: "",
        topics: [],
      };
      const response = await getArticles(payload);
      setArticles(response.data);
    } catch (e) {
      console.log(e.response);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const handleSearchBtnClick = () => {
    navigate("/search");
  };

  const handleCardContentClick = (card) => {
    navigate(`/content/${card._id}/${card.slug}`);
  };

  const likeBtnClick = async (card) => {
    const response = await handleLikeBtnClick(card?._id, dispatch);

    setArticles((prev) =>
      prev.map((article) => {
        if (article._id === card._id) {
          article.likesData = [response.data];
        }
        return article;
      })
    );
  };

  const bookmarkBtnClick = async (card) => {
    const response = await handleBookmarkBtnClick(card._id, dispatch);

    setArticles((prev) =>
      prev.map((article) => {
        if (article._id === card._id) {
          article.bookmarksData = [response.data];
        }
        return article;
      })
    );
  };

  return (
    <>
      <OgTags
        title="LionGuest Studios"
        description="LionGuest Studios is a blogging platform tailored specifically for technical writers."
        imageUrl="https://lgs-nest-prod.s3.ap-south-1.amazonaws.com/lionguest-studios-preview-img.png"
        url="/"
      />
      <div className="user-feed base-margin">
        <div className="ads-sticky">
          <GoogleAds dataAdSlot={"6704848143"} />
        </div>

        <div className="mt-3">
          <div className="mt-3 mb-4 fs-6 fw-bold">Feed</div>
          <div className="feed-items-container">
            {articles.map((data, index) => (
              <FeedCard
                key={data._id}
                article={data}
                handleCardContentClick={handleCardContentClick}
                likeBtnClick={likeBtnClick}
                bookmarkBtnClick={bookmarkBtnClick}
              />
            ))}
          </div>
        </div>

        <div className="ads-sticky">
          <GoogleAds dataAdSlot={"3662264050"} />
        </div>
      </div>
    </>
  );
};

export default UserFeed;
