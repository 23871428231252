import axios from "axios";
import { getCookie } from "../utils/utils";

const host = process.env.REACT_APP_API_ENDPOINT;
const token = getCookie(process.env.REACT_APP_COOKIE_NAME);

axios.defaults.baseURL = host;
axios.defaults.headers.common = {
  Authorization: token ? `Bearer ${token}` : null,
};

export const getUserByUsername = (username) =>
  axios.get(`/user/username/${username}`);

export const isUsernameAvailable = (username) =>
  axios.get(`/user/username/availability/${username}`);

export const updateUsername = (username) =>
  axios.get(`/user/username/set/${username}`);

export const getAllWriters = (username) => axios.get(`/user/getAllWriters`);

export const editUserProfile = (username, payload) =>
  axios.put(`/user/editUserProfile/${username}`, payload);
