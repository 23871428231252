import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./FileUploader.scss";
import { setAlert } from "../../redux/slice/userSlice";
import { useDispatch } from "react-redux";

// Support 1 asset upload
export const FileUploader = ({
  multiple = false,
  acceptFileType,
  customFileUploadClass = "",
  setValue,
  imgVarName,
  uploadtxt = "Select assets to upload",
  isImg,
  selectedImg,
  setSelectedimg,
  register,
}) => {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState(null);

  const handleUploadedAsset = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      if (img.width <= 1400 || img.height <= 900) {
        // formData.append("asset", file);
        // formData.append("assetType", assetType);

        setValue(imgVarName, file);

        file && setFileName(file.name);
        if (file) {
          setSelectedimg(URL.createObjectURL(file));
        }
      } else {
        dispatch(
          setAlert({
            severity: "error",
            open: true,
            message: "Image size greater than aspect ratio",
          })
        );
      }
    };
  };

  return (
    <form
      className="file-uploader-form"
      onClick={() =>
        document.querySelector(".file-uploader-input-field").click()
      }
    >
      <input
        // {...register(imgVarName)}
        type="file"
        accept={acceptFileType}
        className={`file-uploader-input-field ${customFileUploadClass}`}
        onChange={handleUploadedAsset}
        multiple={multiple}
        hidden
      />

      {selectedImg ? (
        <>
          <img
            className="uploaded-img-preview"
            src={selectedImg}
            alt={fileName}
          />
          <div className="file-uploader-name">{fileName}</div>
        </>
      ) : (
        <div className="file-uploader-container">
          <CloudUploadIcon className="upload-file-icon" />
          {uploadtxt}
        </div>
      )}
    </form>
  );
};
