import { Navigate, useNavigate } from "react-router-dom";

const PrivateRoute = ({ children, isRestricted }) => {
  const navigate = useNavigate();
  if (isRestricted) {
    navigate("/");
  }
  return children;
};

export default PrivateRoute;
