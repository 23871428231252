import { useState } from "react";
import { styled } from "@mui/system";
import { TextareaAutosize } from "@mui/material";
import CustomBtn from "../Shared/CustomBtn";
import "./AddComment.scss";
import { addComment } from "../../commons/articleApis";
import { setAlert } from "../../redux/slice/userSlice";
import { useDispatch } from "react-redux";

export const AddComment = ({ article, getAllComments }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");

  const handleCommentData = (e) => {
    setComment(e.target.value);
  };

  const handlePostComment = async () => {
    try {
      const payload = {
        commentData: comment,
        parentId: null,
      };
      const response = await addComment(article?._id, payload);

      if (response.status === 201) {
        getAllComments();
      }

      setComment("");
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  return (
    <div className="add-comment-container">
      <textarea
        className="add-comment-area std-shape-round"
        value={comment}
        aria-label="add comment here"
        placeholder="Your thought matters – share it here.."
        onChange={handleCommentData}
      />
      <CustomBtn
        customBtnClass="post-comment-btn"
        btnTxt="Post Comment"
        onBtnClick={handlePostComment}
        disabled={comment === ""}
      />
    </div>
  );
};
