import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./EditorMetadata.scss";
import CustomBtn from "../Shared/CustomBtn";

export function EditorMetadata({
  topics,
  subtopics,
  selectedTopic,
  setValue,
  selectedSubtopic,
  getAllSubTopics,
  errors,
  register,
}) {
  const handleTopicSelect = (newValue) => {
    getAllSubTopics(newValue._id);
    setValue("topic", newValue);

    // clear subtopic once new topic is selected
    setValue("subtopic", []);
  };

  const handleSubtopicSelect = (newValue) => {
    setValue("subtopic", newValue);
  };

  return (
    <div>
      <div className="metadata-container">
        <div>
          <div className="article-input-fields">
            Topics <span className="text-danger">*</span>
          </div>
          <FormControl fullWidth>
            <Autocomplete
              {...register("topic")}
              value={selectedTopic}
              options={topics}
              getOptionLabel={(option) => option.topicName}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => handleTopicSelect(newValue)}
              className="article-metadata-select-menu"
              renderInput={(params) => <TextField {...params} label="Topics" />}
            />
            <p className="text-danger mt-2">{errors.topic?.message}</p>
          </FormControl>
        </div>

        <div>
          <div className="article-input-fields">
            Subtopics <span className="text-danger">*</span>
          </div>
          <FormControl fullWidth>
            <Autocomplete
              {...register("subtopic")}
              multiple
              value={selectedSubtopic || []}
              options={subtopics || []}
              noOptionsText="Select topic to see subtopics"
              getOptionLabel={(option) => option.subtopic_name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => handleSubtopicSelect(newValue)}
              className="article-metadata-select-menu"
              renderInput={(params) => (
                <TextField {...params} label="Subtopics" />
              )}
            />
            <p className="text-danger mt-2">{errors.subtopic?.message}</p>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
