import "./DesktopHeader.scss";
import { IconButton, TextField } from "@mui/material";
import logo from "../../assets/images/lionguest_studios_logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CustomMenu from "../Shared/CustomMenu";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { SearchBar } from "../Shared/SearchBar";
import LoginIcon from "@mui/icons-material/Login";
import CustomBtn from "../Shared/CustomBtn";

const DesktopHeader = ({
  handleLoginPopupOpen,
  handleDrawerOpen,
  open,
  handleProfile,
  handleUserLogout,
}) => {
  const { userData } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(anchorEl);

  const menuItems = [
    {
      id: 1,
      name: "Profile",
      icon: <PersonIcon />,
      handleItemClick: () => {
        handleProfile();
        handleMenuClose();
      },
    },
    {
      id: 2,
      name: "Logout",
      icon: <LogoutIcon />,
      handleItemClick: () => {
        handleUserLogout();
        handleMenuClose();
      },
    },
  ];

  const handleProfileIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="desktop-header">
        <Link to="/">
          <img src={logo} className="header-logo" />
        </Link>
        <div className="header-search-container">
          <SearchBar />
        </div>
        <div>
          <ul className="heading-links">
            {userData && (
              <li>
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    isActive ? "active-link" : "inactive-link"
                  }
                >
                  Feed
                </NavLink>
              </li>
            )}
            {userData && (
              <li>
                <NavLink
                  to="/writers"
                  className={({ isActive, isPending }) =>
                    isActive ? "active-link" : "inactive-link"
                  }
                >
                  Writers
                </NavLink>
              </li>
            )}
            {/* <li>
              <NavLink
                to="/products"
                className={({ isActive, isPending }) =>
                  isActive ? "active-link" : "inactive-link"
                }
              >
                Products
              </NavLink>
            </li> */}
            {/* <li>
            <Link to="/publications">Publications</Link>
          </li> */}
            {userData && (
              <li>
                <NavLink
                  to="/write"
                  className={({ isActive, isPending }) =>
                    isActive ? "active-link" : "inactive-link"
                  }
                >
                  Publish Your Work
                </NavLink>
              </li>
            )}
            {!userData && (
              <li>
                <CustomBtn
                  customBtnClass="login-header-btn std-shape-round gradient-bg-two"
                  btnTxt="Signin"
                  startIcon={<LoginIcon />}
                  onBtnClick={handleLoginPopupOpen}
                />
              </li>
            )}

            {userData && (
              <div onClick={handleProfileIcon}>
                <img
                  className="header-profile-picture"
                  src={userData?.userData?.profile_pic}
                  alt="logged in user profile photo"
                />
              </div>
            )}
          </ul>

          <IconButton
            className="mobile-header-hamburger"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>
      <CustomMenu
        anchorEl={anchorEl}
        open={isProfileMenuOpen}
        handleClose={handleMenuClose}
        menuItems={menuItems}
      />
    </>
  );
};

export default DesktopHeader;
