import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import Header from "./Components/Header/Header";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Provider } from "react-redux";
import store from "./redux/store";
import Auth from "./auth/Auth";
import AllRoutes from "./Routes/AllRoutes";
import UsernamePopup from "./Components/UsernamePopup/UsernamePopup";
import Footer from "./Components/Footer/Footer";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Header />
          <Auth />
          <AllRoutes />
          <UsernamePopup />
          <Footer />
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
