import LinkedinIcon from "../../assets/images/icons/linkedin_1.svg";
import FacebookIcon from "../../assets/images/icons/facebook_1.svg";
import InstagramIcon from "../../assets/images/icons/instagram_1.svg";
import TwitterIcon from "../../assets/images/icons/twitter_1.svg";
import YoutubeIcon from "../../assets/images/icons/youtube_1.svg";
import "./LGSFollow.scss";

export const LGSFollow = () => {
  return (
    <div className="lgs-follow-icons-lst">
      <a href="https://www.youtube.com/liongueststudios" target="_blank">
        <img
          className="lgs-follow-icon std-shape-round"
          src={YoutubeIcon}
          alt="Youtube icon"
        />
      </a>
      <a href="https://www.instagram.com/liongueststudios/" target="_blank">
        <img
          className="lgs-follow-icon std-shape-round"
          src={InstagramIcon}
          alt="Instagram icon"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/liongueststudios"
        target="_blank"
      >
        <img
          className="lgs-follow-icon std-shape-round"
          src={LinkedinIcon}
          alt="Linkedin icon"
        />
      </a>
      <a href="https://www.facebook.com/liongueststudios/" target="_blank">
        <img
          className="lgs-follow-icon std-shape-round"
          src={FacebookIcon}
          alt="Facebook icon"
        />
      </a>

      <a href="https://twitter.com/liongueststudio" target="_blank">
        <img
          className="lgs-follow-icon std-shape-round"
          src={TwitterIcon}
          alt="Twitter icon"
        />
      </a>
    </div>
  );
};
