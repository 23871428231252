import axios from "axios";
import { getCookie } from "../utils/utils";

const host = process.env.REACT_APP_API_ENDPOINT;
const token = getCookie(process.env.REACT_APP_COOKIE_NAME);

axios.defaults.baseURL = host;
axios.defaults.headers.common = {
  Authorization: token ? `Bearer ${token}` : null,
};

export const successLogin = () => axios.get(`/auth/success`);

export const logout = () => axios.get(`/auth/logout`);

export const googleLogin = () => axios.get(`/auth/google`);
