import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./ProfileTab.scss";
import ArticleCard from "../ArticleCard/ArticleCard";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProfileTab({
  profile,
  usersArticles,
  likeBtnClick,
  bookmarkBtnClick,
}) {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabName = ({ name, count }) => {
    return (
      <span>
        {name}
        <span className="profile-tab-count">{count}</span>
      </span>
    );
  };

  const handleCardContentClick = (card) => {
    navigate(`/content/${card._id}/${card.slug}`);
  };

  return (
    <Box
      className="profile-tab-container base-padding mt-4"
      sx={{ width: "100%" }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className="profile-main-tabs std-shape-round"
          value={value}
          onChange={handleChange}
          aria-label="profile tabs"
        >
          <Tab className="profile-tabs-chips" label="About" {...a11yProps(0)} />
          <Tab
            className="profile-tabs-chips"
            label={<TabName name="Articles" count={usersArticles.length} />}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="profile-about-me mt-4">
          <div className="profile-about-label">About</div>
          <div className="profile-about-value">{profile?.about}</div>
        </div>

        <div className="profile-about-me">
          <div className="profile-about-label">Email</div>
          <div className="profile-about-value">{profile?.email}</div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="profile-article-tab-container mt-4">
          {usersArticles.map((article) => (
            <ArticleCard
              key={article._id}
              article={article}
              handleCardContentClick={handleCardContentClick}
              hideBookmark={true}
              likeBtnClick={likeBtnClick}
              hideUserDetails={true}
              bookmarkBtnClick={bookmarkBtnClick}
            />
          ))}
        </div>
      </TabPanel>
    </Box>
  );
}
