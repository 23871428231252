import "./ArticleContentRight.scss";
import { ListCard } from "../ArticleCard/ListCard";
import { LGSFollow } from "./LGSFollow";
import TopicIcon from "../../assets/images/icons/topic_icon.svg";

const ArticleContentRight = ({ topics, popularArticles, handleTopicClick }) => {
  return (
    <div className="article-content-right">
      {popularArticles.length > 0 && (
        <>
          <h3 className="mb-5">Most Popular </h3>
          {/* <div className="divider-line mt-2 mb-4"></div> */}
          <div className="article-content-popular-articles">
            {popularArticles.slice(0, 3).map((article) => (
              <ListCard key={article._id} article={article} />
            ))}
          </div>
        </>
      )}

      {/* <h3 className="mb-4">Topics</h3>
      <div className="mb-5">
        {topics.slice(0, 4).map((topic) => (
          <div key={topic._id} className="d-flex gap-2">
            <img src={TopicIcon} alt="Topic select icon" />
            <div
              className="article-cont-topic-name"
              onClick={() => handleTopicClick(topic)}
            >
              {topic.topicName}
            </div>
          </div>
        ))}
      </div> */}

      <h3 className="mb-5">Follow Us </h3>
      <LGSFollow />
    </div>
  );
};

export default ArticleContentRight;
