import "./MobileHeader.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";

const MobileHeader = ({
  handleLoginPopupOpen,
  setOpen,
  handleDrawerClose,
  open,
  handleUserLogout,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { userData } = useSelector((state) => state.user);

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    setMenuItems([
      {
        id: 0,
        name: "Login",
        path: "",
        icon: <LoginIcon />,
        handleClick: handleLoginPopupOpen,
        isVisible: !userData ? true : false,
      },
      {
        id: 1,
        name: "Publish Your Work",
        path: "/write",
        icon: <PersonPinOutlinedIcon />,
        handleClick: () => {},
        isVisible: userData ? true : false,
      },
      {
        id: 2,
        name: "Profile",
        path: `/profile/${userData?.userData?.username}`,
        icon: <PersonPinOutlinedIcon />,
        handleClick: () => {},
        isVisible: userData ? true : false,
      },
      // {
      //   id: 3,
      //   name: "Stats",
      //   path: "/profile/:username/stats",
      //   icon: <QueryStatsOutlinedIcon />,
      //   handleClick: () => {},
      // },
      {
        id: 4,
        name: "Writers",
        path: "/writers",
        icon: <QueryStatsOutlinedIcon />,
        handleClick: () => {},
        isVisible: true,
      },
      // { name: "Bookmarks", path: "/",  icon: <BookmarksOutlinedIcon /> },
      // {
      //   id: 5,
      //   name: "Share",
      //   icon: <IosShareOutlinedIcon />,
      //   path: "",
      //   handleClick: () => {},
      // },
      {
        id: 6,
        name: "Logout",
        icon: <LogoutOutlinedIcon />,
        path: "/",
        handleClick: handleUserLogout,
        isVisible: userData ? true : false,
      },
    ]);
  }, [userData]);

  const onDrawerItemClick = (item) => {
    navigate(item.path);
    item.handleClick();
    setOpen(false);
  };

  return (
    <>
      <Drawer
        className="mobile-header"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        <IconButton className="hamburger-icon" onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronLeftIcon /> : <CloseIcon />}
        </IconButton>

        <List>
          {menuItems
            .filter((item) => item.isVisible)
            .map((item) => (
              <div key={item.id} onClick={() => onDrawerItemClick(item)}>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>

                <Divider />
              </div>
            ))}
        </List>
      </Drawer>
    </>
  );
};

export default MobileHeader;
