import { createSlice } from "@reduxjs/toolkit";

const signinSlice = createSlice({
  name: "signin",
  initialState: {
    showSigninPopup: "",
  },
  reducers: {
    setShowSigninPopup: (state, action) => {
      state.showSigninPopup = action.payload;
    },
  },
});

export const { setShowSigninPopup } = signinSlice.actions;

export default signinSlice.reducer;
