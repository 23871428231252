import { useDispatch, useSelector } from "react-redux";
import CustomBtn from "../Shared/CustomBtn";
import Popup from "../Shared/Popup";
import { useEffect, useState } from "react";
import "./UsernamePopup.scss";
import { Input, TextField } from "@mui/material";
import { isUsernameAvailable, updateUsername } from "../../commons/userApis";
import { setAlert, setUserData } from "../../redux/slice/userSlice";

function UsernamePopup() {
  const { userData } = useSelector((state) => state.user);
  const [usernamePopup, setUsernamePopup] = useState(false);
  const [usernameInput, setUsernameInput] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && userData?.userData?.username === "") setUsernamePopup(true);
  }, [userData]);

  useEffect(() => {
    if (usernameInput) checkUsernameAvailable();
  }, [usernameInput]);

  const checkUsernameAvailable = async () => {
    try {
      const response = await isUsernameAvailable(usernameInput);
      if (response.data) setIsAvailable(true);
      else setIsAvailable(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const handleUsernamePopupOpen = () => {
    setUsernamePopup(true);
  };

  const handleUsernamePopupClose = () => {
    setUsernamePopup(false);
  };

  const handleUsernameInput = (e) => {
    setUsernameInput(e.target.value);
  };

  const handleClaimBtnClick = async () => {
    try {
      const response = await updateUsername(usernameInput);

      if (response.data && response.data.message) {
        const updatedUserData = {
          ...userData,
          userData: {
            ...userData.userData,
            username: response.data?.username,
          },
        };

        localStorage.setItem("user-data", JSON.stringify(updatedUserData));
        dispatch(setUserData(updatedUserData));

        dispatch(
          setAlert({
            severity: "success",
            open: true,
            message: response.data.message,
          })
        );

        // refresh jwt token here once username is updated - create supporting api

        // const ls = JSON.parse(localStorage.getItem("user-data"));
        // ls.userData.username = response.data.username;
        // localStorage.setItem("user-data", ls)

        handleUsernamePopupClose();
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };
  return (
    <>
      {usernamePopup && (
        <Popup isOpen={usernamePopup}>
          <div className="username-popup">
            <div className="username-popup-heading">
              Get Your Unique Username
            </div>
            <div className="username-popup-description">
              Stand out from the crowd by choosing a one-of-a-kind username for
              your profile on our platform. Claim your unique identity now!
            </div>

            <TextField
              label="username"
              variant="filled"
              color={usernameInput && isAvailable ? "success" : "error"}
              focused
              value={usernameInput}
              onChange={handleUsernameInput}
            />

            <CustomBtn
              customBtnClass="username-popup-btn"
              btnTxt="Claim Username"
              onBtnClick={handleClaimBtnClick}
              // disabled={!isAvailable}
            />
          </div>
        </Popup>
      )}
    </>
  );
}

export default UsernamePopup;
