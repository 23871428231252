import { useEffect, useState } from "react";
import { getAllWriters } from "../commons/userApis";
import WriterCard from "../Components/WriterCard/WriterCard";
import "./Writers.scss";

const Writers = () => {
  const [writers, setWriters] = useState([]);

  useEffect(() => {
    allWriters();
  }, []);

  const allWriters = async () => {
    try {
      const { data } = await getAllWriters();
      setWriters(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="writers-page base-margin">
      <h2 className="writers-heading">Top Writers</h2>

      <div className="writers-container">
        {writers.map((writer) => (
          <WriterCard key={writer._id} writer={writer} />
        ))}
      </div>
    </div>
  );
};

export default Writers;
