import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchQuery: "",
    searchResults: [],
    selectedTopics: [],
    sortOrder: [
      { id: 1, orderType: 1, orderName: "ascending" },
      { id: 2, orderType: -1, orderName: "descending" },
    ],
    selectedSortOrder: { id: 2, orderType: -1, orderName: "descending" },
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSelectedTopics: (state, action) => {
      state.selectedTopics = action.payload;
    },
    setSelectedSortOrder: (state, action) => {
      state.selectedSortOrder = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
  },
});

export const {
  setSearchQuery,
  setSearchResults,
  setSelectedTopics,
  setSelectedSortOrder,
} = searchSlice.actions;

export default searchSlice.reducer;
