import { Alert, Snackbar, useTheme } from "@mui/material";
import "./Header.scss";
import { useEffect, useState } from "react";
import Popup from "../Shared/Popup";
import CustomBtn from "../Shared/CustomBtn";
import { successLogin } from "../../commons/api";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../redux/slice/userSlice";
import { logoutUser } from "../../utils/utils";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { setShowSigninPopup } from "../../redux/slice/signinSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { alert, userData } = useSelector((state) => state.user);
  const { showSigninPopup } = useSelector((state) => state.signin);

  const tokenName = process.env.REACT_APP_COOKIE_NAME;
  const [cookies, setCookie, removeCookie] = useCookies([
    process.env.REACT_APP_COOKIE_NAME,
  ]);

  // const {
  //   isLoading,
  //   isFetching,
  //   isError,
  //   data: googleLoginData,
  //   refetch: loginWithGoogle,
  // } = useQuery("signin", getUserData, { enabled: false });

  const googleAuth = async () => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/google/redirect`,
      "_self"
    );
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLoginPopupOpen = () => {
    dispatch(setShowSigninPopup(true));
  };

  const handleLoginPopupClose = () => {
    dispatch(setShowSigninPopup(false));
  };

  const handleAlertClose = () => {
    dispatch(setAlert({ ...alert, open: false, message: "" }));
  };

  const handleProfile = () => {
    navigate(`/profile/${userData?.userData?.username}`);
  };

  const handleUserLogout = () => {
    logoutUser(tokenName, removeCookie, dispatch, navigate);
  };

  return (
    <>
      <div className="header base-margin">
        <DesktopHeader
          handleLoginPopupOpen={handleLoginPopupOpen}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleProfile={handleProfile}
          handleUserLogout={handleUserLogout}
        />
        <MobileHeader
          handleLoginPopupOpen={handleLoginPopupOpen}
          setOpen={setOpen}
          handleDrawerClose={handleDrawerClose}
          open={open}
          handleUserLogout={handleUserLogout}
        />

        {showSigninPopup && (
          <Popup isOpen={showSigninPopup} handleClose={handleLoginPopupClose}>
            <div className="signin-popup">
              <h2 className="signin-heading">Be a part of global community</h2>
              <p className="signin-subheading">
                Signin to publish and explore top-notch content crafted by
                exceptional writers.
              </p>
              <CustomBtn
                customBtnClass="google-btn std-shape-round"
                btnTxt="Signin with Google"
                onBtnClick={() => {
                  googleAuth();
                }}
              />
              <div className="mt-2">(One click signin)</div>
            </div>
          </Popup>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alert.open}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
