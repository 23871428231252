import "./LandingPage.scss";
import CustomBtn from "../Components/Shared/CustomBtn";
import HeroMainImg from "../assets/images/header-main-img.gif";
import SectionTwoImg from "../assets/images/community-people.png";
import WorkOneImg from "../assets/images/person-new.png";
import WorkTwoImg from "../assets/images/person-reading.png";
import WorkThreeImg from "../assets/images/person-sharing.png";
import YTSectionImg from "../assets/images/youtube-section-img.gif";
import { setAlert } from "../redux/slice/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getArticles } from "../commons/articleApis";
import ArticleCard from "../Components/ArticleCard/ArticleCard";
import { setShowSigninPopup } from "../redux/slice/signinSlice";
import OgTags from "../utils/Ogtags";

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = async () => {
    try {
      const payload = {
        search: "",
        topics: [],
      };
      const response = await getArticles(payload);
      setArticles(response.data);
    } catch (e) {
      console.log(e.response);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const WordCard = ({ stepNo, stepDesc, imgSrc }) => {
    return (
      <div>
        <div className="work-grp-txt">
          <div className="work-comm-steps">Step {stepNo}</div>
          <div>{stepDesc}</div>
        </div>
        <img className="work-comm-imgs" src={imgSrc} />
      </div>
    );
  };

  const handleLoginPopupOpen = () => {
    dispatch(setShowSigninPopup(true));
  };

  const handleCardContentClick = (card) => {
    navigate(`/content/${card._id}/${card.slug}`);
  };

  const handleYTBtnClick = (card) => {
    window.open("https://www.youtube.com/@LionGuestStudios/videos", "_blank");
  };

  return (
    <>
      <OgTags
        title="LionGuest Studios"
        description="LionGuest Studios is a blogging platform tailored specifically for technical writers."
        imageUrl="https://lgs-nest-prod.s3.ap-south-1.amazonaws.com/lionguest-studios-preview-img.png"
        url="/"
      />

      <div className="landing-page-container">
        <div className="landing-section-one base-padding">
          <div className="hero-left">
            <h2>Publish your tech articles to a global audience</h2>
            <div className="hero-left-desc">
              A platform to distribute your work to a wide audience and to
              enable readers to access high-quality articles.
            </div>
            <CustomBtn
              customBtnClass="std-shape-round"
              btnTxt="Get Started"
              onBtnClick={handleLoginPopupOpen}
            />
          </div>
          <div className="hero-right">
            <img src={HeroMainImg} />
          </div>
        </div>
        <div className="landing-section-two base-margin">
          <div className="section-two-left">
            <img src={SectionTwoImg} />
          </div>
          <div className="section-two-right">
            <h2>Engineered by Tech Enthusiasts, for Tech Enthusiasts.</h2>
            <div className="section-abt-desc">
              Whether you’re a tech savvy writer eager to produce content on a
              wide array of technological subjects or an avid reader looking to
              expand your knowledge base, our platform has been meticulously
              designed and crafted to cater your needs.
            </div>
          </div>
        </div>
        <div className="landing-section-three base-padding">
          <h2 className="section-three-txt">How it works?</h2>
          <div className="section-grp-container">
            <WordCard
              stepNo={1}
              stepDesc={"Sign Up for Free"}
              imgSrc={WorkOneImg}
            />
            <WordCard
              stepNo={2}
              stepDesc={"Research, Write and Publish"}
              imgSrc={WorkTwoImg}
            />
            <WordCard
              stepNo={3}
              stepDesc={"Engage with the Community"}
              imgSrc={WorkThreeImg}
            />
          </div>
        </div>
        <div className="landing-section-four base-margin">
          <h2 className="section-four-txt">Editor's pick</h2>
          <div className="landing-article-list">
            {articles.slice(0, 3).map((article, index) => (
              <ArticleCard
                key={article._id}
                article={article}
                handleCardContentClick={handleCardContentClick}
                hideCardEngagements={true}
                hideBookmark={true}
              />
            ))}
          </div>
        </div>
        <div className="landing-section-five base-padding">
          <div className="section-two-left">
            <h2>Explore, Watch, and Learn</h2>
            <div className="section-abt-desc">
              Elevate your tech journey with visually rich, informative videos.
              Don't just read about technology – experience it in action!
            </div>
            <CustomBtn
              customBtnClass="yt-hero-cta std-shape-round"
              btnTxt="Discover on YouTube"
              onBtnClick={handleYTBtnClick}
            />
          </div>
          <div className="section-two-right">
            <img src={YTSectionImg} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
