import {
  Autocomplete,
  Chip,
  FormControl,
  Input,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./SearchPage.scss";
import ArticleCard from "../Components/ArticleCard/ArticleCard";
import { useNavigate } from "react-router-dom";
import {
  setSearchResults,
  setSelectedSortOrder,
  setSelectedTopics,
} from "../redux/slice/searchSlice";
import { handleBookmarkBtnClick, handleLikeBtnClick } from "../utils/utils";

const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { topics } = useSelector((state) => state.topic);
  const {
    sortOrder,
    selectedSortOrder,
    searchResults,
    searchQuery,
    selectedTopics,
  } = useSelector((state) => state.search);

  const handleSort = (newValue) => {
    dispatch(setSelectedSortOrder(newValue));
  };

  const handleTopicSelect = (topic) => {
    dispatch(setSelectedTopics(topic));
  };

  const handleCardContentClick = (card) => {
    navigate(`/content/${card._id}/${card.slug}`);
  };

  const onDelete = (topic) => {
    const val = selectedTopics.filter((item) => item._id !== topic._id);
    dispatch(setSelectedTopics(val));
  };

  const bookmarkBtnClick = async (e, card) => {
    e.stopPropagation();
    const response = await handleBookmarkBtnClick(card._id, dispatch);

    dispatch((dispatch, getState) => {
      const prev = getState();

      const updatedValues = prev?.search?.searchResults?.map((article) => {
        if (article._id === card._id) {
          return {
            ...article,
            bookmarksData: [response.data],
          };
        }
      });

      dispatch(setSearchResults(updatedValues));
    });
  };

  console.log("wqwqwq", searchResults);

  return (
    <div className="search-page-container gradient-bg-two">
      <div className="search-section base-margin">
        <div className="search-results-section">
          <h2 className="mt-2 mb-5">Filters:</h2>
          <div className="search-bar-container ">
            <div className="search-page-filters-container">
              <div className="global-filters-container">
                <div className="global-search-select-menu">
                  <Autocomplete
                    multiple={true}
                    options={topics}
                    getOptionLabel={(option) => option.topicName}
                    value={selectedTopics}
                    onChange={(event, newValue) => {
                      handleTopicSelect(newValue);
                    }}
                    renderTags={() => null}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    placeholder="Choose topics"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Choose Topics"
                      />
                    )}
                  />
                </div>

                <div className="global-search-select-menu">
                  <Autocomplete
                    options={sortOrder}
                    getOptionLabel={(option) => option.orderName}
                    value={selectedSortOrder}
                    onChange={(event, newValue) => handleSort(newValue)}
                    renderTags={() => null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    placeholder="Choose Sort Order"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Choose Sort Order"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-2 mb-2">
            {(selectedTopics || []).map((item) => (
              <Chip
                key={item._id}
                label={item.topicName}
                onDelete={() => onDelete(item)}
              />
            ))}
          </div>

          <h2 className="mt-2 mb-5">Results:</h2>

          {searchResults.length > 0 ? (
            <div className="search-results-cards">
              {searchResults.length > 0
                ? searchResults.map((article) => (
                    <ArticleCard
                      key={article._id}
                      article={article}
                      hideCardEngagements={true}
                      handleCardContentClick={handleCardContentClick}
                      bookmarkBtnClick={bookmarkBtnClick}
                    />
                  ))
                : searchQuery !== "" &&
                  topics.length !== 0 && (
                    <div className="text-center">
                      No articles found for the provided search{" "}
                    </div>
                  )}
            </div>
          ) : (
            <div className="text-center">No results found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
