import ArticleContentLeft from "../Components/MainContent/ArticleContentLeft";
import ArticleContentRight from "../Components/MainContent/ArticleContentRight";
import { useEffect, useState } from "react";
import {
  getComments,
  getFilteredArticles,
  getSingleArticle,
} from "../commons/articleApis";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../redux/slice/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTopics, handleLikeBtnClick } from "../utils/utils";
import "./ArticleDetailPage.scss";
import Progress from "../Components/Shared/Progress/Progress";
import OgTags from "../utils/Ogtags";

const ArticleDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [article, setArticle] = useState(null);
  const [isProgress, setIsProgress] = useState(false);
  const [similarArticles, setSimilarArticles] = useState([]);
  const [popularArticles, setPopularArticles] = useState([]);

  const [commentsData, setCommentsData] = useState([]);

  const { topics } = useSelector((state) => state.topic);

  useEffect(() => {
    getArticle();

    if (topics.length === 0) getAllTopics(dispatch);
  }, [params.id]);

  useEffect(() => {
    if (article) {
      getFilterArticles("similarArticles");
      getFilterArticles("popularArticles");
      getAllComments();
    }
  }, [params.id, article?._id]);

  const getArticle = async () => {
    try {
      setIsProgress(true);
      const response = await getSingleArticle(params?.id);
      setArticle(response.data);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    } finally {
      setIsProgress(false);
    }
  };

  const getFilterArticles = async (filterType) => {
    try {
      const payload = { topicId: article?.topicId, currArticleId: article._id };
      const response = await getFilteredArticles(filterType, payload);

      if (filterType === "similarArticles") {
        setSimilarArticles(response.data);
      }

      if (filterType === "popularArticles") {
        setPopularArticles(response.data);
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const getAllComments = async () => {
    try {
      const response = await getComments(article._id);
      setCommentsData(response.data);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    } finally {
      setIsProgress(false);
    }
  };

  const likeBtnClick = async (article) => {
    const response = await handleLikeBtnClick(article?._id, dispatch);

    setArticle((prev) => ({
      ...prev,
      likesData: response.data,
    }));
  };

  const handleTopicClick = (topic) => {
    navigate(`/topics/${topic._id}`);
  };

  return (
    <>
      {isProgress ? (
        <Progress />
      ) : (
        <main>
          <article className="article-details-pg-cont base-margin">
            <OgTags
              title={article?.title}
              description={article?.plainContent?.slice(0, 200)}
              imageUrl={article?.featuredImage}
              url={`${process.env.REACT_APP_FE_URL}/content/${article?._id}/${article?.slug}`}
              type="article"
              authorName={`${article?.posterData?.first_name} ${article?.posterData?.last_name}`}
            />

            <div className="article-details-top-content">
              <div className="d-flex gap-2 flex-wrap">
                <div className="article-details-topic std-shape-round gradient-bg-two">
                  {article?.topicData?.topicName}
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  {(article?.subtopicsData || []).map((item) => (
                    <div
                      key={item._id}
                      className="article-details-topic std-shape-round"
                    >
                      {" "}
                      {item.subtopic_name}
                    </div>
                  ))}
                </div>
              </div>

              <div className="article-details-title">{article?.title}</div>
              {article?.subtitle && (
                <div className="article-details-subtopic">
                  {article?.subtitle}
                </div>
              )}
            </div>

            <div className="article-details-page">
              <ArticleContentLeft
                article={article}
                likeBtnClick={likeBtnClick}
                similarArticles={similarArticles}
                commentsData={commentsData}
                getAllComments={getAllComments}
              />
              <ArticleContentRight
                topics={topics}
                popularArticles={popularArticles}
                handleTopicClick={handleTopicClick}
              />
            </div>
          </article>
        </main>
      )}
    </>
  );
};

export default ArticleDetailPage;
