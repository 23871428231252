import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import topicSlice from "./slice/topicSlice";
import searchSlice from "./slice/searchSlice";
import signinSlice from "./slice/signinSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    topic: topicSlice,
    search: searchSlice,
    signin: signinSlice,
  },
});

export default store;
