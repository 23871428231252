import { createReactEditorJS } from "react-editor-js";
import Paragraph from "@editorjs/paragraph";
import Code from "@editorjs/code";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import ImageTool from "@editorjs/image";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import InlineCode from "@editorjs/inline-code";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import { uploadAssetToS3 } from "../../commons/articleApis";
import { useCallback, useEffect } from "react";

import createGenericInlineTool, {
  ItalicInlineTool,
  UnderlineInlineTool,
} from "editorjs-inline-tool";

const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  embed: Embed,
  table: Table,
  list: List,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: ImageTool,
    config: {
      uploader: {
        async uploadByFile(file) {
          try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await uploadAssetToS3(formData);

            if (response.data.url) {
              return {
                success: 1,
                file: response.data,
              };
            }
          } catch (e) {
            console.log(e);
          }
        },
      },
    },
  },
  raw: Raw,
  header: {
    class: Header,
    inlineToolbar: true,
  },
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  inlineCode: {
    class: InlineCode,
    shortcut: "CMD+SHIFT+M",
  },
  // bold: {
  //   class: createGenericInlineTool({
  //     sanitize: {
  //       strong: {},
  //     },
  //     shortcut: "CMD+B",
  //     tagName: "STRONG",
  //     toolboxIcon:
  //       '<svg class="icon icon--bold" width="12px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#bold"></use></svg>',
  //   }),
  // },
  // italic: ItalicInlineTool,
  underline: UnderlineInlineTool,
  // simpleImage: SimpleImage,
};

const ReactEditorJS = createReactEditorJS();

export function EditorNew({
  richContent,
  setValue,
  key = "richContent",
  placeholder = "Starting writing here...",
}) {
  const handleEditorDataChange = (data) => {
    setValue(key, data);
  };

  const handleInitialize = useCallback((instance) => {
    setValue(key, instance);
  }, []);

  // const handleSave = useCallback(async () => {
  //   const savedData = await editorCore.current.save();
  // }, []);

  return (
    <>
      {richContent && (
        <ReactEditorJS
          onInitialize={handleInitialize}
          tools={EDITOR_JS_TOOLS}
          onChange={(data) => handleEditorDataChange(data)}
          data={richContent}
          placeholder={placeholder}
        />
      )}
    </>
  );
}
