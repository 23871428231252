import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getUserByUsername } from "../commons/userApis";
import { setAlert } from "../redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import "./ProfilePage.scss";
import Linkedin from "../assets/images/icons/linkedin.svg";
import Facebook from "../assets/images/icons/facebook.svg";
import YoutubeIcon from "../assets/images/icons/youtube.svg";
import Instagram from "../assets/images/icons/instagram.svg";
import Github from "../assets/images/icons/github.svg";
import Twitter from "../assets/images/icons/twitter.svg";
import PublicIcon from "@mui/icons-material/Public";
import ProfileTab from "../Components/ProfileTab/ProfileTab";
import EditIcon from "../assets/images/icons/edit.svg";
import { getFilteredArticles } from "../commons/articleApis";
import CustomBtn from "../Components/Shared/CustomBtn";
import { handleBookmarkBtnClick, handleLikeBtnClick } from "../utils/utils";
import Popup from "../Components/Shared/Popup";
import EditProfile from "../Components/ProfileTab/EditProfile";

const ProfilePage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  const [profile, setProfile] = useState(null);
  const [usersArticles, setUsersArticles] = useState([]);

  const [editProfilePopup, setEditProfilePopup] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (profile) {
      getArticlesOfLoggedInUser();
    }
  }, [profile]);

  const getProfileData = async () => {
    try {
      const response = await getUserByUsername(params?.username);
      setProfile(response.data);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const getArticlesOfLoggedInUser = async () => {
    try {
      const payload = { userId: profile?._id };
      const response = await getFilteredArticles("userArticles", payload);
      setUsersArticles(response.data);
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  const likeBtnClick = async (e, card) => {
    e.stopPropagation();
    const response = await handleLikeBtnClick(card?._id, dispatch);

    setUsersArticles((prev) =>
      prev.map((article) => {
        if (article._id === card._id) {
          article.likesData = [response.data];
        }
        return article;
      })
    );
  };

  const bookmarkBtnClick = async (e, card) => {
    e.stopPropagation();
    const response = await handleBookmarkBtnClick(card._id, dispatch);

    setUsersArticles((prev) =>
      prev.map((article) => {
        if (article._id === card._id) {
          article.bookmarksData = [response.data];
        }
        return article;
      })
    );
  };

  const handleEditProfilePopupClose = () => {
    setEditProfilePopup(false);
  };

  const handleEditProfilePopupOpen = () => {
    setEditProfilePopup(true);
  };

  const handlePostComment = () => {
    handleEditProfilePopupOpen();
  };

  return (
    <>
      <div className="profile-container">
        <div className="profile-main-section">
          <div className="profile_img-cont base-margin">
            <img className="profile_img" src={profile?.profile_pic} />
          </div>
          <div className="profile-bg-section">
            {/* <img className="profile-edit-icon std-shape-round" src={EditIcon} /> */}
          </div>
          <div className="profile-user-section base-margin">
            <div className="profile-user-details">
              <div className="user-details-left">
                <div className="profile-name">
                  {profile?.first_name} {profile?.last_name}
                </div>
                <div className="profile-username mb-2">
                  @{profile?.username}
                </div>
                <div className="profile-bio">{profile?.bio}</div>

                {/* <div className="profile-people-count">
                  <div className="profile-follow">0 Followers</div>
                  <div className="profile-follow">0 Following</div>
                </div> */}
              </div>

              <div className="user-details-right">
                <div className="edit-profile-top">
                  {params?.username === userData?.userData?.username && (
                    <CustomBtn
                      customBtnClass="edit-profile-btn std-shape-round"
                      btnTxt="Edit Profile"
                      onBtnClick={handlePostComment}
                    />
                  )}
                </div>
                <div className="social-bottom">
                  <div className="connect-with-me-txt">Connect with me</div>
                  <div className="profile-social-platforms std-shape-round">
                    {profile?.social_links?.linkedin && (
                      <a href={profile?.social_links.linkedin} target="_blank">
                        <img src={Linkedin} alt="linkedin profile" />
                      </a>
                    )}
                    {profile?.social_links?.instagram && (
                      <a href={profile?.social_links.instagram} target="_blank">
                        <img src={Instagram} alt="instagram profile" />
                      </a>
                    )}
                    {profile?.social_links?.github && (
                      <a href={profile?.social_links.github} target="_blank">
                        <img src={Github} alt="github profile" />
                      </a>
                    )}
                    {profile?.social_links?.twitter && (
                      <a href={profile?.social_links.twitter} target="_blank">
                        <img src={Twitter} alt="twitter profile" />
                      </a>
                    )}
                    {profile?.social_links?.facebook && (
                      <a href={profile?.social_links.facebook} target="_blank">
                        <img src={Facebook} alt="facebook profile" />
                      </a>
                    )}
                    {profile?.social_links?.youtube && (
                      <a href={profile?.social_links.youtube} target="_blank">
                        <img src={YoutubeIcon} alt="youtube channel" />
                      </a>
                    )}
                    {profile?.social_links?.website && (
                      <a href={profile?.social_links.website} target="_blank">
                        <PublicIcon />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileTab
        profile={profile}
        usersArticles={usersArticles}
        likeBtnClick={likeBtnClick}
        bookmarkBtnClick={bookmarkBtnClick}
      />

      {editProfilePopup && (
        <Popup
          isOpen={editProfilePopup}
          handleClose={handleEditProfilePopupClose}
        >
          <EditProfile
            handleEditProfilePopupClose={handleEditProfilePopupClose}
            getProfileData={getProfileData}
            profile={profile}
          />
        </Popup>
      )}
    </>
  );
};

export default ProfilePage;
