import {
  Autocomplete,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./SearchBar.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTopics } from "../../utils/utils";
import { setAlert } from "../../redux/slice/userSlice";
import { getArticles } from "../../commons/articleApis";
import {
  setSearchQuery,
  setSearchResults,
} from "../../redux/slice/searchSlice";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../assets/images/icons/file.svg";
import SearchIcon from "../../assets/images/icons/search.svg";
import Progress from "./Progress/Progress";

export const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { topics } = useSelector((state) => state.topic);
  const { selectedTopics, selectedSortOrder, searchQuery, searchResults } =
    useSelector((state) => state.search);

  useEffect(() => {
    if (topics.length === 0) getAllTopics(dispatch);
  }, []);

  useEffect(() => {
    // If user clicks outside then hide the dropdown
    const handler = () => setShowDropDown(false);
    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (searchQuery || selectedTopics.length > 0) getAllArticles(controller);

    return () => controller.abort();
  }, [searchQuery, selectedSortOrder, selectedTopics[0]]);

  const getAllArticles = async (controller) => {
    try {
      setIsLoading(true);

      const topicIds = JSON.stringify(selectedTopics.map((item) => item._id));

      const payload = {
        search: searchQuery,
        topics: topicIds,
        sortOrder: selectedSortOrder.orderType,
      };

      const response = await getArticles(payload, controller.signal);
      dispatch(setSearchResults(response.data));
    } catch (e) {
      console.log(e.response);
      // dispatch(
      //   setAlert({
      //     severity: "error",
      //     open: true,
      //     message: e.response?.data?.message,
      //   })
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    const query = e.target.value;
    dispatch(setSearchQuery(query));
    setShowDropDown(true);
  };

  const handleItemClick = (article) => {
    navigate(`/content/${article._id}/${article.slug}`);
    setShowDropDown(false);
  };

  const handleKeyPress = (event) => {
    // when the Enter key is pressed
    if (event.keyCode === 13 && searchQuery.length > 0) {
      setShowDropDown(false);
      navigate(`/search`);
    }
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <img src={SearchIcon} className="dropdown-file-icon" alt="file icon" />
        <input
          type="text"
          value={searchQuery}
          placeholder="Search content across the platform"
          onChange={handleQueryChange}
          onKeyDown={handleKeyPress}
        />
      </div>

      {isLoading ? (
        <LinearProgress className="search-loader" color="secondary" />
      ) : (
        searchQuery &&
        showDropDown && (
          <div className="search-dropdown">
            {searchResults.length > 0 ? (
              searchResults.map((res, index) => (
                <>
                  <div key={res._id} className="dropdown-item">
                    <img
                      src={res.featuredImage}
                      className="dropdown-file-icon"
                      alt="file icon"
                    />

                    <div
                      className="search-dropdown-txt"
                      onClick={() => handleItemClick(res)}
                    >
                      {res.title}
                    </div>
                  </div>
                  {index < searchResults.length - 1 && (
                    <div className="divider-line mx-2"></div>
                  )}
                </>
              ))
            ) : (
              <div className="text-center">No results found</div>
            )}
          </div>
        )
      )}
    </div>
  );
};
