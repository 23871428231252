import { Dialog } from "@mui/material";
import "./Popup.scss";

const Popup = ({ children, isOpen, handleClose }) => {
  return (
    <Dialog className="shared-dialog" open={isOpen} onClose={handleClose}>
      <div className="popup-inner-container">{children}</div>
    </Dialog>
  );
};

export default Popup;
