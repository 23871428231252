import axios from "axios";
import { getCookie } from "../utils/utils";

const host = process.env.REACT_APP_API_ENDPOINT;
const token = "getCookie(process.env.REACT_APP_COOKIE_NAME);";

axios.defaults.baseURL = host;
axios.defaults.headers.common = {
  Authorization: token ? `Bearer ${token}` : null,
};

export const createArticle = (payload) =>
  axios.post(`/article/createArticle`, payload);

export const editArticle = (articleId, payload) =>
  axios.put(`/article/editArticle/${articleId}`, payload);

export const getTopics = () => axios.get(`/article/getTopics`);

export const getSubTopics = (topicId) =>
  axios.get(`/article/getSubTopics/${topicId}`);

export const getArticles = (payload, signal) => {
  const sortOrder = payload.sortOrder ? payload.sortOrder : -1;
  return axios.get(
    `/article/all?search=${payload.search}&topics=${payload.topics}&sortOrder=${sortOrder}`,
    {
      signal: signal,
    }
  );
};

export const getSingleArticle = (articleId) =>
  axios.get(`/article/${articleId}`);

export const likeArticle = (articleId) =>
  axios.patch(`/article/likeArticle/${articleId}`);

export const bookmarkArticle = (articleId) =>
  axios.patch(`/article/bookmark/${articleId}`);

export const getFilteredArticles = (filterType, params) =>
  axios.get(
    `/article/filteredArticles/${filterType}?topicId=${params?.topicId}&currArticleId=${params?.currArticleId}&userId=${params.userId}`
  );

export const uploadAssetToS3 = (formData) =>
  axios.post(`/article/asset/upload/s3`, formData);

export const addComment = (articleId, payload) =>
  axios.post(`/article/addComment/${articleId}`, payload);

export const getComments = (articleId) =>
  axios.get(`/article/comments/${articleId}`);
