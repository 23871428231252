import * as React from "react";

import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { Link, useNavigate } from "react-router-dom";
import { Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import "./Footer.scss";

export default function Footer() {
  const navigate = useNavigate();
  const [color, setColor] = React.useState("#010101");
  return (
    <Sheet
      variant="solid"
      invertedColors
      className="footer-container"
      sx={{
        ...{
          bgcolor: `${color}`,
        },
        flexGrow: 1,
        p: 2,
        borderRadius: { xs: 0, sm: "sm" },
        paddingLeft: "8%",
        paddingRight: "8%",
      }}
      sm={{
        display: "block",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <a href={"https://www.facebook.com/liongueststudios"} target="_blank">
          <IconButton variant="plain">
            <FacebookRoundedIcon />
          </IconButton>
        </a>
        <a href={"https://twitter.com/liongueststudio"} target="_blank">
          <IconButton variant="plain">
            <Twitter />
          </IconButton>
        </a>
        <a href={"https://www.instagram.com/liongueststudios/"} target="_blank">
          <IconButton variant="plain">
            <Instagram />
          </IconButton>
        </a>
        <a href={"https://www.linkedin.com/company/14656196/"} target="_blank">
          <IconButton variant="plain">
            <LinkedIn />
          </IconButton>
        </a>
        <a href={"https://www.youtube.com/@liongueststudios"} target="_blank">
          <IconButton variant="plain">
            <YouTube />
          </IconButton>
        </a>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
          Copyright © 2024 LionGuest Studios. All rights reserved.
        </Typography>
      </Box>
      {/* <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { md: "flex-start" },
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <List
          size="sm"
          orientation="horizontal"
          wrap
          sx={{
            flexGrow: 0,
            "--ListItem-radius": "8px",
            "--ListItem-gap": "0px",
          }}
        >
          {/* <ListItem nested sx={{ width: { xs: "50%", md: 180 } }}>
            <ListSubheader sx={{ fontWeight: "xl" }}>Sitemap</ListSubheader>
            <List sx={{ "--ListItemDecorator-size": "32px" }}>
              <ListItem
                onClick={() => {
                  navigate("/writers");
                }}
              >
                <ListItemButton>Writers</ListItemButton>
              </ListItem>
            </List>
          </ListItem> */}
      {/* <ListItem nested sx={{ width: { xs: "50%", md: 180 } }}>
            <ListSubheader sx={{ fontWeight: "xl" }}>Contribute</ListSubheader>
            <List sx={{ "--ListItemDecorator-size": "32px" }}>
              <ListItem
                onClick={() => {
                  navigate("https://www.paypal.com/paypalme/sahilbhosale63");
                }}
              >
                <ListItemButton>Paypal</ListItemButton>
              </ListItem>
             
            </List>
          </ListItem> */}
      {/* </List>
      </Box> */}
    </Sheet>
  );
}
