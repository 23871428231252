import { useDispatch, useSelector } from "react-redux";
import { setAlert, setUserData } from "../redux/slice/userSlice";
import { successLogin } from "../commons/api";
import { useEffect, useLayoutEffect, useState } from "react";
import { getCookie, logoutUser } from "../utils/utils";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokenName = process.env.REACT_APP_COOKIE_NAME;
  const [cookies, setCookie, removeCookie] = useCookies([tokenName]);
  const [isCookieExpired, setIsCookieExpirede] = useState(false);

  useEffect(() => {
    let interval;

    const data = localStorage.getItem("user-data")
      ? JSON.parse(localStorage.getItem("user-data"))
      : null;

    if (data && isCookieExpired)
      logoutUser(tokenName, removeCookie, dispatch, navigate);
    else checkLsAndCookie(data);

    if (data) {
      interval = setInterval(() => {
        checkCookie(data);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [isCookieExpired]);

  const checkCookie = (data) => {
    const current = new Date().getTime() / 1000; // converting curr date to seconds
    const expiry = data.exp; // already in seconds

    if (expiry < current) setIsCookieExpirede(true);
  };

  const checkLsAndCookie = (isPresentInLS) => {
    if (isPresentInLS) dispatch(setUserData(isPresentInLS));

    const isCookiePresent = getCookie(tokenName);

    if (isCookiePresent && !isPresentInLS) {
      getUserData();
    }
  };

  const getUserData = async () => {
    try {
      const response = await successLogin();

      dispatch(setUserData(response.data));
      localStorage.setItem("user-data", JSON.stringify(response.data));
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e.response.data.message,
        })
      );
    }
  };

  return <></>;
};

export default Auth;
