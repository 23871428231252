import React from "react";
import { Helmet } from "react-helmet";

const OgTags = ({
  title,
  description,
  imageUrl,
  url,
  type = "website",
  authorName = "",
}) => {
  return (
    <Helmet>
      <meta property="og:site_name" content="LionGuest Studios" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type} />
      <meta name="title" property="og:title" content={title} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta property="og:url" content={url} />
      <meta name="image" property="og:image" content={imageUrl} />
      {authorName && <meta name="author" content={authorName} />}
    </Helmet>
  );
};

export default OgTags;
