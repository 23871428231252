import "./FeedCard.scss";
import AuthorImg from "../../assets/images/sahil_bhosale.png";
import IosShareIcon from "@mui/icons-material/IosShare";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import DotsIcon from "../../assets/images/icons/dots.svg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CustomMenu from "../Shared/CustomMenu";
import { useState } from "react";
import moment from "moment";
import CustomBtn from "../Shared/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { copyTextToClipboard } from "../../utils/utils";
import BookmarkIcon from "@mui/icons-material/Bookmark";

function FeedCard({
  article,
  handleCardContentClick,
  likeBtnClick,
  bookmarkBtnClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  const {
    _id,
    posterData,
    title,
    subtitle,
    plainContent,
    featuredImage,
    slug,
    likesData,
    bookmarksData,
    createdAt,
    commentsData,
  } = article;

  const menuItems = [
    {
      id: 1,
      name: "Bookmark",
      icon:
        bookmarksData.length > 0 &&
        bookmarksData[0]?.bookmarkedBy?.includes(userData?.userId) ? (
          <BookmarkIcon
            className="feed-card-bookmark-icon-select-color"
            fontSize="large"
          />
        ) : (
          <BookmarkBorderIcon fontSize="large" />
        ),
      handleItemClick: () => {
        bookmarkBtnClick(article);
        handleMenuClose();
      },
    },
  ];

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="feed-card-container">
        <div className="card-contents">
          <div className="d-flex justify-content-between align-items-center">
            <div className="card-profile">
              <img className="author-img" src={posterData.profile_pic} />
              <div className="author-metadata">
                <div className="author-name">
                  {posterData?.first_name} {posterData?.last_name}
                </div>
                {posterData?.username && (
                  <span className="feed-username">{`@${posterData?.username}`}</span>
                )}
                <div className="feed-date">{moment(createdAt).fromNow()}</div>
              </div>
            </div>
            <CustomBtn
              customBtnClass={"rounded"}
              variant="text"
              btnTxt={<img src={DotsIcon} />}
              onBtnClick={handleDotsClick}
            />
          </div>

          <div
            className="main-content"
            onClick={() => handleCardContentClick(article)}
          >
            <h2 className="card-heading">{title}</h2>
            {/* {subtitle && <p className="card-heading">{subtitle}</p>} */}
            {plainContent && (
              <div className="card-description">{plainContent}</div>
            )}
            <div className="img-container">
              <img src={featuredImage} className="card-featured-img" />
            </div>
          </div>

          <div className="divider-line"></div>
          <div className="card-engagements">
            <div className="card-likes" onClick={() => likeBtnClick(article)}>
              {likesData.length &&
              likesData[0]?.likedBy?.includes(userData?.userId) ? (
                <FavoriteIcon className="feed-card-like-btn" fontSize="large" />
              ) : (
                <FavoriteBorderIcon
                  className="feed-card-icon-unselect-color"
                  fontSize="large"
                />
              )}

              <span className="ms-2">
                {likesData.length > 0 ? likesData[0]?.likedBy?.length : 0} Likes
              </span>
            </div>
            <div className="card-comments">
              <ChatBubbleOutlineIcon fontSize="large" />
              <span className="ms-2">{commentsData?.length} Comments</span>
            </div>
            <button
              className="card-share-btn"
              onClick={() => {
                const url = `${window.location.href}content/${_id}/${slug}`;
                copyTextToClipboard(url, "Link copied!", dispatch);
              }}
            >
              <IosShareIcon className="share-icon" fontSize="large" />
            </button>
          </div>
        </div>
      </div>

      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleMenuClose}
        menuItems={menuItems}
      />
    </>
  );
}

export default FeedCard;
