import "./EditProfile.scss";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { EditorNew } from "../ArticleEditor/EditorNew";
import { useEffect, useState } from "react";
import CustomBtn from "../Shared/CustomBtn";
import { setAlert } from "../../redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { removeHtmlAndLinks } from "../../utils/utils";
import { editUserProfile } from "../../commons/userApis";

const editProfileFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(1)
    .max(15)
    .typeError("First name is required")
    .required(),
  last_name: yup
    .string()
    .min(1)
    .max(15)
    .typeError("First name is required")
    .required(),
  bio: yup.string().min(3).max(60).typeError("Bio is required").required(),
  // about: yup.object(),
  linkedin: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?(?:linkedin)\.com\/[a-zA-Z0-9_]+/,
      "Enter correct url!"
    ),
  instagram: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?(?:instagram)\.com\/[a-zA-Z0-9_]+/,
      "Enter correct url!"
    ),
  twitter: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?(?:twitter)\.com\/[a-zA-Z0-9_]+/,
      "Enter correct url!"
    ),
  facebook: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?(?:facebook)\.com\/[a-zA-Z0-9_]+/,
      "Enter correct url!"
    ),
  website: yup
    .string()
    .matches(
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      "Enter correct url!"
    ),
  youtube: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?(?:youtube)\.com\/[a-zA-Z0-9_]+/,
      "Enter correct url!"
    ),
});

const EditProfile = ({
  handleEditProfilePopupClose,
  getProfileData,
  profile,
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      // about: null,
    },
    reValidateMode: "onChange",
    mode: "all",
    resolver: yupResolver(editProfileFormSchema),
  });

  useEffect(() => {
    if (userData) {
      const { facebook, instagram, linkedin, twitter, website, youtube } =
        profile?.social_links;

      setValue("first_name", profile?.first_name);
      setValue("last_name", profile?.last_name);
      setValue("bio", profile?.bio);
      setValue("facebook", facebook);
      setValue("instagram", instagram);
      setValue("linkedin", linkedin);
      setValue("twitter", twitter);
      setValue("website", website);
      setValue("youtube", youtube);

      // if (userData.userData.about !== "") {
      //   const aboutContent = JSON.parse(userData.userData.about);
      //   setValue("about", aboutContent);
      // } else {
      //   const defaultVal = {
      //     time: 1689185386905,
      //     blocks: [],
      //     version: "2.27.2",
      //   };
      //   setValue("about", defaultVal);
      // }
    }
  }, []);

  const first_name = watch("first_name");
  const last_name = watch("last_name");
  const bio = watch("bio");
  const linkedin = watch("linkedin");
  const instagram = watch("instagram");
  const twitter = watch("twitter");
  const facebook = watch("facebook");
  const website = watch("website");
  const youtube = watch("youtube");
  // const about = watch("about");

  const [isLoading, setIsLoading] = useState(false);

  // console.log("lsqqq", about);

  const handlePublish = async () => {
    try {
      setIsLoading(true);

      let savedData;

      // if (about?._editorJS) {
      //   savedData = await about?._editorJS?.save();
      // } else {
      //   savedData = await about?.saver?.save();
      // }

      // console.log("dklsmdls", savedData);

      const plainContent = savedData?.blocks
        .map((block) => block.data.text)
        .join(" ");

      const sanitizedPlainContent = removeHtmlAndLinks(plainContent);

      // if (!savedData) {
      //   throw {
      //     response: { data: { message: "About is required" } },
      //   };
      // }

      const payload = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        bio: bio,
        social_links: {
          linkedin: linkedin,
          facebook: facebook,
          instagram: instagram,
          twitter: twitter,
          youtube: youtube,
          website: website,
        },

        // about: sanitizedPlainContent,
        // aboutRichContent: JSON.stringify(savedData),
      };

      const response = await editUserProfile(
        userData?.userData?.username,
        payload
      );

      if ([200, 201].includes(response.status)) {
        const { message, isSuccess } = response?.data;
        getProfileData();
        handleEditProfilePopupClose();

        reset();

        dispatch(
          setAlert({
            severity: isSuccess ? "success" : "error",
            open: true,
            message: message,
          })
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setAlert({
          severity: "error",
          open: true,
          message: e?.response?.data
            ? e.response.data.message
            : "Something went wrong",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="edit-profile">
      <h2 className="edit-profile-heading">Edit Profile</h2>

      <div>
        <div className="edit-profile-placeholder">Name</div>
        <div className="edit-profile-grp">
          <div>
            <input
              {...register("first_name")}
              className="edit-profile-input std-shape-round"
              placeholder="First Name"
              value={first_name}
            />
            <p className="text-danger mt-2">{errors.first_name?.message}</p>
          </div>

          <div>
            <input
              {...register("last_name")}
              className="edit-profile-input std-shape-round"
              placeholder="Last Name"
              value={last_name}
            />
            <p className="text-danger mt-2">{errors.last_name?.message}</p>
          </div>
        </div>
      </div>

      <div>
        <div className="edit-profile-placeholder">Bio</div>
        <div className="edit-profile-grp">
          <input
            {...register("bio")}
            className="edit-profile-input std-shape-round"
            placeholder="Bio"
            value={bio}
          />
          <p className="text-danger mt-2">{errors.bio?.message}</p>
        </div>
      </div>

      <div>
        <div className="edit-profile-placeholder">Social Links</div>

        <div className="edit-profile-grp">
          <div>
            <input
              {...register("linkedin")}
              className="edit-profile-input std-shape-round"
              placeholder="Linkedin"
              value={linkedin}
            />
            <p className="text-danger mt-2">{errors.linkedin?.message}</p>
          </div>

          <div>
            <input
              {...register("instagram")}
              className="edit-profile-input std-shape-round"
              placeholder="Instagram"
              value={instagram}
            />
            <p className="text-danger mt-2">{errors.instagram?.message}</p>
          </div>

          <div>
            <input
              {...register("twitter")}
              className="edit-profile-input std-shape-round"
              placeholder="Twitter"
              value={twitter}
            />
            <p className="text-danger mt-2">{errors.twitter?.message}</p>
          </div>

          <div>
            <input
              {...register("facebook")}
              className="edit-profile-input std-shape-round"
              placeholder="Facebook"
              value={facebook}
            />
            <p className="text-danger mt-2">{errors.facebook?.message}</p>
          </div>

          <div>
            <input
              {...register("youtube")}
              className="edit-profile-input std-shape-round"
              placeholder="Youtube"
              value={youtube}
            />
            <p className="text-danger mt-2">{errors.youtube?.message}</p>
          </div>

          <div>
            <input
              {...register("website")}
              className="edit-profile-input std-shape-round"
              placeholder="Website"
              value={website}
            />
            <p className="text-danger mt-2">{errors.website?.message}</p>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="edit-profile-placeholder">About</div>

        <EditorNew
          richContent={about}
          key="about"
          setValue={setValue}
          placeholder="Write about yourself here..."
        />
      </div> */}

      <div className="edit-profile-btn-container">
        <CustomBtn
          customBtnClass="text-btn discard-btn"
          onBtnClick={handleEditProfilePopupClose}
          variant="text"
          btnTxt="Cancel"
        />

        <CustomBtn
          customBtnClass="edit-profile-save-btn std-shape-round"
          onBtnClick={handleSubmit(handlePublish)}
          btnTxt="Save changes"
          type={"submit"}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default EditProfile;
