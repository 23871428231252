import { Button } from "@mui/material";
import "./CustomBtn.scss";

const CustomBtn = ({
  customBtnClass,
  btnTxt,
  variant = "contained",
  onBtnClick,
  startIcon,
  disabled = false,
  type,
}) => {
  return (
    <Button
      startIcon={startIcon}
      className={`custom-btn ${customBtnClass}`}
      onClick={onBtnClick}
      variant={variant}
      disabled={disabled}
      type={type}
    >
      {btnTxt}
    </Button>
  );
};

export default CustomBtn;
