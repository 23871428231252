import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../WebPages/LandingPage";
import WriteArticle from "../WebPages/WriteArticle";
import Writers from "../WebPages/Writers";
import Publications from "../WebPages/Publications";
import PrivateRoute from "../Routes/PrivateRoute";
import UserFeed from "../WebPages/UserFeed";
import SearchPage from "../WebPages/SearchPage";
import ProfilePage from "../WebPages/ProfilePage";
import ArticleDetailPage from "../WebPages/ArticleDetailPage";

const AllRoutes = () => {
  const { userData } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={userData ? <UserFeed /> : <LandingPage />}
      />
      <Route
        path="/write"
        element={
          <PrivateRoute isRestricted={userData ? false : true}>
            <WriteArticle />
          </PrivateRoute>
        }
      />
      <Route
        path="/write/:articleId"
        element={
          <PrivateRoute isRestricted={userData ? false : true}>
            <WriteArticle />
          </PrivateRoute>
        }
      />
      <Route path="/writers" element={<Writers />} />
      <Route
        path="/publications"
        element={
          <PrivateRoute isRestricted={userData ? false : true}>
            <Publications />
          </PrivateRoute>
        }
      />

      <Route path="/profile/:username" element={<ProfilePage />} />

      <Route path="/search" element={<SearchPage />} />

      <Route path="/content/:id/:slug" element={<ArticleDetailPage />} />
    </Routes>
  );
};

export default AllRoutes;
