import "./Progress.scss";

export default function Progress() {
  return (
    <div className="progress-container">
      <div className="loader">
        <div className="dot"></div>
        <div className="dot">G</div>
        <div className="dot"></div>
        <div className="dot">N</div>
        <div className="dot">D</div>
        <div className="dot">I</div>
        <div className="dot">A</div>
        <div className="dot"></div>
        <div className="dot">O</div>
        <div className="dot"></div>
        <div className="dot">L</div>
        <div className="dot"></div>
      </div>
    </div>
  );
}
