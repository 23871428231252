import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import IosShareIcon from "@mui/icons-material/IosShare";
import FavoriteIcon from "@mui/icons-material/Favorite";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "./ArticleContentLeft.scss";
import parse from "html-react-parser";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyTextToClipboard } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import ArticleCard from "../ArticleCard/ArticleCard";
import { AddComment } from "../Comment/AddComment";
import { SingleComment } from "../Comment/SingleComment";
import { CommentsList } from "../Comment/CommentList";
import { useRef } from "react";
import CustomBtn from "../Shared/CustomBtn";
import EditIcon from "../../assets/images/icons/edit.svg";
import { SvgIcon } from "@mui/material";
import GoogleAds from "../../commons/component/GoogleAds";

const ArticleContentLeft = ({
  article,
  likeBtnClick,
  similarArticles,
  commentsData,
  getAllComments,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const commentSectionRef = useRef(null);

  const renderBlocks = (editorData) => {
    if (!editorData || !editorData.blocks) {
      return null;
    }

    return editorData.blocks.map((block, index) => {
      if (index !== 0 && Math.ceil(index / 14) * 14 === index) {
        return <GoogleAds dataAdSlot={"4119634448"} customClass="mt-5 mb-5" />;
      }

      if (block.type === "paragraph") {
        return <p key={index}>{parse(block.data.text)}</p>;
      }

      if (block.type === "image") {
        const imageUrl = block.data.file.url;

        return (
          <div key={index}>
            <img
              className={`article-featured-img ${
                block.data.withBorder ? "article-featured-img-border" : ""
              }`}
              src={imageUrl}
              alt="Editor Image"
            />
            {block.data.caption && (
              <p className="article-featured-caption">{block.data.caption}</p>
            )}
          </div>
        );
      }

      if (block.type === "embed") {
        const src = block.data.source;
        return (
          <div key={block._id}>
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="360"
              src={src}
            ></iframe>
          </div>
        );
      }

      if (block.type === "checklist") {
        const checklistItems = block.data.items;
        return (
          <ul key={index}>
            {checklistItems.map((item, itemIndex) => (
              <li key={itemIndex}>
                <input type="checkbox" checked={item.checked} readOnly />
                <span>{parse(item.text)}</span>
              </li>
            ))}
          </ul>
        );
      }

      if (block.type === "header") {
        const headerLevel = block.data.level;
        const headerText = block.data.text;
        const HeaderTag = `h${headerLevel}`;

        return <HeaderTag key={index}>{parse(headerText)}</HeaderTag>;
      }

      if (block.type === "quote") {
        const quoteText = block.data.text;
        const quoteCaption = block.data.caption;
        return (
          <div
            key={index}
            className="article-detail-quote-container std-shape-round gradient-bg-two"
          >
            <blockquote key={index} className="article-detail-quote">
              "{parse(quoteText)}"
            </blockquote>
            {quoteCaption && (
              <p className="article-detail-quote-caption">- {quoteCaption}</p>
            )}
          </div>
        );
      }

      if (block.type === "link") {
        const linkText = block.data.text;
        const linkUrl = block.data.link;
        return (
          <a
            key={index}
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        );
      }

      if (block.type === "code") {
        const codeText = block.data.code;

        return (
          <div key={index} className="article-detail-code-snippet">
            <SyntaxHighlighter className="p-4" style={docco}>
              {codeText}
            </SyntaxHighlighter>
          </div>
        );
      }

      if (block.type === "list") {
        const listItems = block.data.items;
        return (
          <ul key={index}>
            {listItems.map((item, itemIndex) => (
              <li key={itemIndex}>{parse(item)}</li>
            ))}
          </ul>
        );
      }

      if (block.type === "table") {
        const tableData = block.data.content;
        return (
          <table key={index}>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{parse(cell)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }

      if (block.type === "linkTool") {
        const linkText = block.data.link;
        const linkUrl = block.data.meta.href;
        return (
          <a
            key={index}
            href={linkText}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        );
      }

      return null;
    });
  };

  const handleCardContentClick = (card) => {
    navigate(`/content/${card._id}/${card.slug}`);
  };

  const handleCommentBtn = () => {
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleArticleEdit = () => {
    navigate(`/write/${article._id}`);
  };

  const goToProfile = () => {
    navigate(`/profile/${article?.posterData?.username}`);
  };

  return (
    <div className="article-content-left">
      <img className="article-main-img" src={article?.featuredImage} />

      {article?.postedBy === userData?.userData?._id && (
        <div
          className="d-flex justify-content-end mt-4"
          onClick={handleArticleEdit}
        >
          <CustomBtn
            customBtnClass="article-details-edit-btn std-shape-round"
            btnTxt="Edit Article"
            startIcon={<img src={EditIcon} />}
            // onBtnClick={handlePostComment}
          />
        </div>
      )}

      <div className="user-article-grp">
        <div className="article-user-details" onClick={goToProfile}>
          <img className="author-img" src={article?.posterData?.profile_pic} />
          <div className="author-metadata">
            <div className="author-name">
              {article?.posterData?.first_name} {article?.posterData?.last_name}
            </div>
            {article?.posterData?.username && (
              <span className="feed-username">{`@${article?.posterData?.username}`}</span>
            )}
            <div className="feed-date">
              Published {moment(article?.createdAt).fromNow()}
            </div>
          </div>
        </div>

        <div className="article-interactions">
          <div className="article-likes" onClick={() => likeBtnClick(article)}>
            {article?.likesData?.likedBy?.includes(userData?.userId) ? (
              <FavoriteIcon
                className="article-detail-like-btn"
                fontSize="large"
              />
            ) : (
              <FavoriteBorderIcon
                className="article-icon-unselect-color"
                fontSize="large"
              />
            )}

            <span className="ms-2">
              {article?.likesData ? article?.likesData?.likedBy?.length : 0}{" "}
              Likes
            </span>
          </div>
          <div className="article-comments" onClick={handleCommentBtn}>
            <ChatBubbleOutlineIcon
              className="article-icon-unselect-color"
              fontSize="large"
            />
            <span className="ms-2">{commentsData.count} Comments</span>
          </div>
          <button
            className="article-share-btn"
            onClick={() =>
              copyTextToClipboard(
                window.location.href,
                "Link copied!",
                dispatch
              )
            }
          >
            <IosShareIcon
              className="share-icon article-icon-unselect-color"
              fontSize="large"
            />
          </button>
        </div>
      </div>

      <div className="article-description">
        {article?.richContent && (
          <div>{renderBlocks(JSON.parse(article?.richContent))}</div>
        )}
      </div>

      <div className="divider-line mt-5 mb-4"></div>

      {similarArticles.length > 0 && (
        <>
          <div className="article-details-similar-card-cont">
            <h3 className="article-details-similar-art-txt">
              Related Articles Worth Checking Out{" "}
            </h3>

            <div className="article-details-similar-cards">
              {/* We only want to display 3 similar articles */}
              {similarArticles.slice(0, 3).map((article) => (
                <ArticleCard
                  key={article._id}
                  article={article}
                  handleCardContentClick={handleCardContentClick}
                />
              ))}
            </div>
          </div>
          <div className="divider-line mt-5 mb-3"></div>
        </>
      )}

      <div className="article-details-comments-section" ref={commentSectionRef}>
        <h3 className="article-details-comments-txt">Liked the article</h3>
        <div className="article-details-comments-subtxt">
          Comment your throughts below
        </div>

        <AddComment article={article} getAllComments={getAllComments} />
        <div className="ad-comments-list">
          <CommentsList commentsData={commentsData} />
        </div>
      </div>
    </div>
  );
};

export default ArticleContentLeft;
