import { Divider, Menu, MenuItem } from "@mui/material";
import "./CustomMenu.scss";

const CustomMenu = ({ anchorEl, open, handleClose, menuItems = [] }) => {
  return (
    <Menu
      id="custom-menu"
      className="custom-menu-style"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {menuItems.map((item, index) => (
        <div key={item?.id}>
          <MenuItem onClick={item?.handleItemClick}>
            <span className="custom-menu-icon">{item.icon}</span>{" "}
            <span className="ms-3">{item?.name}</span>
            {index < item?.length - 1 && <Divider />}
          </MenuItem>
        </div>
      ))}
    </Menu>
  );
};

export default CustomMenu;
